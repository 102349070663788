import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useUserProfile} from "../../hooks/useUserProfile";

export default function SignOut(){
    const navigate = useNavigate();
    const {session, logOut} = useUserProfile();

    useEffect(() => {
        logOut();
    }, [logOut])

    useEffect(() => {
        if(!session?.user){
            navigate('/profile/');
        }
    }, [session, navigate])


    return null;
}