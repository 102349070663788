import {
    Badge,
    Collapse, FormControl, FormHelperText, InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader, MenuItem, Select,
    Slider, Tooltip
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CountrySelect from "../UI/Atoms/CountrySelect";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AirportSelect from "../UI/Atoms/AirportSelect";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs, {Dayjs} from "dayjs";
import IconButton from "@mui/material/IconButton";
import SaveIcon from '@mui/icons-material/Save';
import LanguageIcon from '@mui/icons-material/Language';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SaveFilterDialog from "./SaveFIlterDialog";
import {useUserProfile} from "../../hooks/useUserProfile";
import Button from "@mui/material/Button";

function valuetext(value: number) {
    return `${value}°C`;
}

interface Props {
    closeSidebar: () => void
}

export default function Filter(props: Props) {
    const { closeSidebar } = props;
    const {t} = useTranslation();
    const [filterState, setSearchParams] = useSearchParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const {session} = useUserProfile();
    const navigate = useNavigate();

    const hasCategoryData = (category: string) => {
        switch(category){
            case "country":
                return filterState.has("fromCountry") || filterState.has("toCountry");
            case "airport":
                return filterState.has("fromAirport") || filterState.has("toAirport");
            case "price":
                return filterState.has("priceFrom") || filterState.has("priceTo");
            case "date":
                return filterState.has("departureDateFrom") || filterState.has("departureDateTo") || filterState.has("returnDateFrom") || filterState.has("returnDateTo")
            default:
                return false;
        }
    }

    const defaultOpenState = new Map([
        ["country", hasCategoryData("country")],
        ["airport" , hasCategoryData("airport")],
        ["price" , hasCategoryData("price")],
        ["date" , hasCategoryData("date")],
    ]);
    const [openState, setOpenState] = useState<Map<string, boolean>>(defaultOpenState);

    const priceMax = 20000

    const handleClick = (sectionName: string) => {
        const newOpenState = new Map(openState);
        newOpenState.set(sectionName, !openState.get(sectionName));
        setOpenState(newOpenState);
    };
    const handleChangeFilterState = (key: string, newValue: any) => {
        const newSearchParams = new URLSearchParams(filterState);
        if(newValue === undefined || newValue === null){
            newSearchParams.delete(key);
        }else{
            newSearchParams.set(key, newValue);
        }
        setSearchParams(newSearchParams);
    };

    const getFilterState = (key: string) => {
        return filterState.get(key);
    }

    const getFilterDateState = (key: string) => {
        const value = filterState.get(key);
        if(value == null){
            return null;
        }
        return dayjs.unix(parseInt(value));
    }

    const openModal = () => {
        if(!session?.user || !session){
            navigate('/profile/');
        }
        setModalOpen(true);
    }

    const priceSliderValue = [getFilterState("priceFrom")?? 0, getFilterState("priceTo")?? 50000];

    return (<>
        <SaveFilterDialog isOpen={isModalOpen} setIsOpen={setModalOpen} filterState={filterState} />
        <List
        subheader={
            <ListSubheader component="div" style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
                {t("Nastavení filtru akcí")}
                <Tooltip title={t("filter.save-fast-filter")}>
                    <IconButton onClick={() => openModal()} style={{margin:-8}}>
                        <SaveIcon/>
                    </IconButton>
                </Tooltip>
            </ListSubheader>
        }
    >
        <ListItemButton onClick={() => handleClick("country")}>
            <ListItemIcon>
                <Badge color="primary" variant="dot" invisible={!hasCategoryData("country")}>
                    <LanguageIcon />
                </Badge>
            </ListItemIcon>
            <ListItemText primary={t("filter.by-country")} />
            {openState.get("country") ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openState.get("country")} timeout="auto">
                <ListItem>
                    <CountrySelect
                        label={t("filter.from-country")}
                        countryCode={getFilterState("fromCountry")}
                        onChange={(event, value) => {
                            handleChangeFilterState("fromCountry", value?.code);
                        }}
                    />
                </ListItem>
                <ListItem>
                    <CountrySelect
                        label={t("filter.to-country")}
                        countryCode={getFilterState("toCountry")}
                        onChange={(event, value) => {
                            handleChangeFilterState("toCountry", value?.code);
                        }}
                    />
                </ListItem>
        </Collapse>

        <ListItemButton onClick={() => handleClick("airport")}>
            <ListItemIcon>
                <Badge color="primary" variant="dot" invisible={!hasCategoryData("airport")}>
                    <LocalAirportIcon />
                </Badge>
            </ListItemIcon>
            <ListItemText primary={t("filter.by-airport")} />
            {openState.get("airport") ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openState.get("airport")} timeout="auto" unmountOnExit>
            <ListItem>
                <AirportSelect
                    label={t("filter.from-airport")}
                    airportCode={getFilterState("fromAirport")}
                    onChange={(event, value) => {
                        handleChangeFilterState("fromAirport", value?.code);
                    }}
                />
            </ListItem>
            <ListItem>
                <AirportSelect
                    label={t("filter.to-airport")}
                    airportCode={getFilterState("toAirport")}
                    onChange={(event, value) => {
                        handleChangeFilterState("toAirport", value?.code);
                    }}
                />
            </ListItem>
        </Collapse>

        <ListItemButton onClick={() => handleClick("price")}>
            <ListItemIcon>
                <Badge color="primary" variant="dot" invisible={!hasCategoryData("price")}>
                    <CurrencyBitcoinIcon />
                </Badge>
            </ListItemIcon>
            <ListItemText primary={t("filter.by-price")} />
            {openState.get("price") ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openState.get("price")} timeout="auto" unmountOnExit>
            <ListItem>
                <Slider
                    getAriaLabel={() => t("filter.price-range")}
                    // @ts-ignore
                    value={priceSliderValue}
                    onChange={(event, newValue) => {
                        // @ts-ignore
                        if(newValue[0] != priceSliderValue[0]){
                            // @ts-ignore
                            handleChangeFilterState("priceFrom", newValue[0]);
                        }
                        // @ts-ignore
                        if(newValue[1] != priceSliderValue[1]) {
                            // @ts-ignore
                            if(priceMax == newValue[1]){
                                handleChangeFilterState("priceTo", null);
                            }else {
                                // @ts-ignore
                                handleChangeFilterState("priceTo", newValue[1]);
                            }
                        }
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    valueLabelFormat={(value) => {
                        if(value == priceMax){
                            return t("filter.price-max");
                        }
                        return value.toLocaleString("cs-CZ", {
                            style: "currency",
                            currency: getFilterState("priceCurrency")?? "czk",
                            maximumFractionDigits: 0,
                        })
                    }}
                    min={0}
                    max={priceMax}
                    step={1000}
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t("filter.price-currency")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={getFilterState("priceCurrency")?? "czk"}
                        label={t("filter.currency")}
                        onChange={(event) => {
                            handleChangeFilterState("priceCurrency", event.target.value);
                        }}
                    >
                        <MenuItem value={"czk"}>CZK - Kč</MenuItem>
                        <MenuItem value={"eur"}>EUR - €</MenuItem>
                        <MenuItem value={"usd"}>USD - $</MenuItem>
                    </Select>
                    <FormHelperText>{t("filter.currency-note")}</FormHelperText>
                </FormControl>
            </ListItem>
        </Collapse>

        <ListItemButton onClick={() => handleClick("date")}>
            <ListItemIcon>
                <Badge color="primary" variant="dot" invisible={!hasCategoryData("date")}>
                    <DateRangeIcon />
                </Badge>
            </ListItemIcon>
            <ListItemText primary={t("filter.by-date")} />
            {openState.get("date") ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openState.get("date")} timeout="auto" unmountOnExit>
            <ListItem>
                <FormControl fullWidth>
                    <DatePicker
                        label={t("filter.departure-date-from")}
                        value={getFilterDateState("departureDateFrom")}
                        slotProps={{
                            field: { clearable: true },
                        }}
                        onChange={(newValues: null|Dayjs) => {
                            handleChangeFilterState("departureDateFrom", newValues?.unix());
                        }}
                    />
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <DatePicker
                        label={t("filter.departure-date-to")}
                        value={getFilterDateState("departureDateTo")}
                        slotProps={{
                            field: { clearable: true },
                        }}
                        onChange={(newValues: null|Dayjs) => {
                            handleChangeFilterState("departureDateTo", newValues?.unix());
                        }}
                    />
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <DatePicker
                        label={t("filter.return-date-from")}
                        value={getFilterDateState("returnDateFrom")}
                        slotProps={{
                            field: { clearable: true },
                        }}
                        onChange={(newValues: null|Dayjs) => {
                            handleChangeFilterState("returnDateFrom", newValues?.unix());
                        }}
                    />
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <DatePicker
                        label={t("filter.return-date-to")}
                        value={getFilterDateState("returnDateTo")}
                        slotProps={{
                            field: { clearable: true },
                        }}
                        onChange={(newValues: null|Dayjs) => {
                            handleChangeFilterState("returnDateTo", newValues?.unix());
                        }}
                    />
                </FormControl>
            </ListItem>
        </Collapse>
        <ListItem sx={{
            display: "flex",
            flexDirection: "row-reverse"
        }}>
            <Button sx={{display: { xs: 'block', sm: 'none' }}} variant="contained" onClick={closeSidebar}>
                Zobrazit Akce
            </Button>
        </ListItem>
    </List>
    </>);
}