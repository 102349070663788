import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserMenu from "../../User/UserMenu";
import QuickSearch from "./QuickSearch";

type Props = {
    title: string
    icon: JSX.Element|null
}

export default function AppHeader(props: Props) {
    const { icon, title } = props;
    return (
        <AppBar position="sticky" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                {icon}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                <UserMenu/>
            </Toolbar>
        </AppBar>
    );
}