import React from "react";
import styled from "styled-components";

function Price(props: {
    priceCurrency: string,
    price: number
}) {
    const { priceCurrency, price } = props;
    try {
        const priceFormat = new Intl.NumberFormat('cs-CZ', {
            style: "currency", currency: priceCurrency
        });
        const divProps = (({price, priceCurrency, ...o}) => o)(props);
        return <div {...divProps}>{price ? priceFormat.format(price) : ''}</div>
    }catch (e) {
        console.error(e)
        return <div>{price ? priceCurrency + " " + price : ''}</div>
    }


}

export default styled(Price)`
    font-size: 1.4rem;
    color: white;
    font-weight: 500;
    margin: 0;
    padding: 0;
`