import Cookies from 'js-cookie'
import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from "@apollo/client";
import {RetryLink} from "@apollo/client/link/retry";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';
import {LocalStorageWrapper, persistCache} from "apollo3-cache-persist";
import supabase from "../auth/supabase";

const API_HOST = 'https://api.coolletenky.cz/graphql/'
//const API_HOST = 'http://localhost/graphql/'


const getApolloClient = async () => {
    const getApiHost = () => {
        if (typeof process.env.REACT_APP_API_HOST !== 'undefined') {
            return process.env.REACT_APP_API_HOST
        }
        return API_HOST;
    }
    const httpLink = new HttpLink({
        uri: getApiHost(),
        credentials: "include"
    })
    const retryLink = new RetryLink({attempts: {max: Infinity}})

    const authLink = setContext(async (_, { headers }) => {
        const token = (await supabase.auth.getSession()).data.session?.access_token

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : '',
            },
        }
    })

    const errorLink = onError(({networkError}) => {
        if (networkError && networkError.statusCode === 401) {
            Cookies.remove('token')
            window.location.replace('/login')
        }
    })

    const queueLink = new QueueLink()

    window.addEventListener('offline', () => queueLink.close())
    window.addEventListener('online', () => queueLink.open())

    const serializingLink = new SerializingLink()

    const trackerLink = new ApolloLink((operation, forward) => {
        if (forward === undefined) return null

        const context = operation.getContext()
        const trackedQueries = JSON.parse(window.localStorage.getItem('trackedQueries') || null) || []

        if (context.tracked !== undefined) {
            const {operationName, query, variables} = operation

            const newTrackedQuery = {
                query,
                context,
                variables,
                operationName,
            }

            window.localStorage.setItem('trackedQueries', JSON.stringify([...trackedQueries, newTrackedQuery]))
        }

        return forward(operation).map((data) => {
            if (context.tracked !== undefined) {
                window.localStorage.setItem('trackedQueries', JSON.stringify(trackedQueries))
            }

            return data
        })
    })

    const link = ApolloLink.from([
        trackerLink,
        queueLink,
        serializingLink,
        retryLink,
        errorLink,
        authLink,
        httpLink
    ])

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    actions: {
                        keyArgs: ["filterId", "quickFilter", "liked"],
                        merge(existing, incoming, {readField}) {
                            let merged = new Map();
                            if(typeof existing !== "undefined"
                                && existing.hasOwnProperty('items') &&
                                existing.items instanceof Map
                            ){
                                merged = new Map([...existing.items]);
                            }

                            incoming.forEach(item => {
                                merged.set(readField("id", item), item);
                            });

                            const sortedEntries = new Map([...merged].sort(
                                (a, b) => readField("createdAt", a) - readField("createdAt", b)
                            ));

                            return {
                                items: sortedEntries
                            }
                        },

                        read(existing) {
                            if(!existing){
                                return;
                            }
                            if(!existing.hasOwnProperty('items')){
                                return
                            }
                            if(!(existing.items instanceof Map)){
                                return;
                            }
                            return Array.from(existing.items.values())
                        },
                    }
                }
            }
        }
    })

    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
    });

    return new ApolloClient({
        link,
        cache,
    })
}

export default getApolloClient