import {CircularProgress, Grid} from "@mui/material";
import {Action} from "./Action";
import React from "react";
import loadActions, {ActionQueryResult, ActionType} from "../../query/loadActions";
import {useQuery} from "@apollo/client";
import TriggerWhenInViewport from "../Common/TriggerWhenInViewport";
import {useSearchParams} from "react-router-dom";

type Props = {
    filterId?: string
    toCountryCode?: string
    filterLiked?: boolean
}

export default function List (props: Props) {
    const convertToIntegerOrNull = (value: string|null) => {
        if(value === null){
            return null;
        }
        return parseInt(value);
    }
    const getQuickFilter = () => {
        return {
            priceFrom: convertToIntegerOrNull(filterData.get('priceFrom')),
            priceTo: convertToIntegerOrNull(filterData.get('priceTo')),
            priceCurrency: filterData.get('priceCurrency')??"czk",
            fromCountry: filterData.get('fromCountry')??null,
            toCountry: filterData.get('toCountry')??null,
            fromAirport: filterData.get('fromAirport')??null,
            toAirport: filterData.get('toAirport')??null,
            departureDateFrom: convertToIntegerOrNull(filterData.get('departureDateFrom')),
            departureDateTo: convertToIntegerOrNull(filterData.get('departureDateTo')),
            returnDateFrom: convertToIntegerOrNull(filterData.get('returnDateFrom')),
            returnDateTo: convertToIntegerOrNull(filterData.get('returnDateTo')),
        }
    }
    const {filterId, filterLiked} = props;
    const [filterData] = useSearchParams();
    const {data, fetchMore} = useQuery<ActionQueryResult>(loadActions, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filterId: filterId,
            liked: filterLiked,
            quickFilter: getQuickFilter()
        }
    });

    const loadingComponent = function(){
        return <Grid container spacing={2} padding={2} paddingBottom={10} id={"salam"} alignItems={"center"} justifyContent={"space-around"}>
            <CircularProgress />
        </Grid>;
    }

    if(typeof data === 'undefined'){
        return loadingComponent();
    }

    const actions = data.actions;

    if(actions.length === 0){
        return <Grid container spacing={2} padding={2} paddingBottom={10} id={"salam"} alignItems={"center"} justifyContent={"space-around"}>
            No actions found
        </Grid>;
    }

    const renderActions = () => {
        return actions.map((item, key) => {
            let lastItem = null;
            if(key > 0){
                lastItem = actions[key-1];
            }
            return <Action key={item.id} action={item} itemBefore={lastItem}/>;
        })
    }

    const lastItem: ActionType = actions.slice(-1)[0]

    return (
        <>
        <Grid container spacing={2} padding={2} paddingBottom={10}>
            {renderActions()}
            <TriggerWhenInViewport onInViewport={() => fetchMore({
                variables:{
                    time: lastItem.createdAt
                }
            })} />
        </Grid>
        </>
    )
}