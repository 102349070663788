import {Box, Drawer, SwipeableDrawer} from "@mui/material";

const drawerWidth = 350;

type Props = {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    drawer: JSX.Element
};

export const SideBar = (props: Props) => {
    const {drawer, isOpen, onClose, onOpen} = props;
    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <SwipeableDrawer
                container={container}
                variant="temporary"
                open={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </SwipeableDrawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}