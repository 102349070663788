import React from "react";
import {Link, useLocation} from "react-router-dom";
import Container from "@mui/material/Container";

export default function PrivacyPolicy() {
    const location = useLocation();
    return (
        <Container style={{paddingBottom: 200}}>
            <article id="07a3e5d7-6c45-43ba-9429-88b39c6edc51" className="page sans">
                <header><h1 className="page-title"><strong>PRIVACY POLICY</strong></h1><p
                    className="page-description"></p></header>
                <div className="page-body"><p id="78e19b36-7194-47be-a807-18b4551df922" className=""><strong>Last
                    updated March 18, 2024</strong></p><p id="5d66b787-3298-4731-99ea-f085c60269ef" className="">This
                    privacy notice for coolletenky.cz (&quot;<strong>we</strong>,&quot; &quot;
                    <strong>us</strong>,&quot; or &quot;<strong>our</strong>&quot;), describes how and why we might
                    collect, store, use, and/or share (&quot;<strong>process</strong>&quot;) your information when you
                    use our services (&quot;<strong>Services</strong>&quot;), such as when you:</p>
                    <ul id="9d07edda-4b9b-47fb-afe9-ae9543a6dfce" className="bulleted-list">
                        <li >Visit our website at <a
                            href="https://coolletenky.cz/">https://coolletenky.cz</a>, or any website of ours that links
                            to this privacy notice
                        </li>
                    </ul>
                    <ul id="672ad953-e3db-42d4-96b1-d43f5eeba82f" className="bulleted-list">
                        <li >Engage with us in other related ways, including any sales,
                            marketing, or events
                        </li>
                    </ul>
                    <p id="17d5024d-28aa-45b5-a006-4ed5ac3ea81e" className=""><strong>Questions or concerns? </strong>Reading
                        this privacy notice will help you understand your privacy rights and choices. If you do not
                        agree with our policies and practices, please do not use our Services. If you still have any
                        questions or concerns, please contact us at privacy@coolletenky.cz.</p><p
                        id="b01978b4-c357-45ef-b19f-24c5400aca0b" className=""><strong>SUMMARY OF KEY POINTS</strong>
                    </p><p id="c0522255-00c2-461d-bf36-6ac8717d97b0" className=""><em><strong>This summary provides key
                        points from our privacy notice, but you can find out more details about any of these topics by
                        clicking the link following each key point or by using our </strong></em><em><strong><a
                        href="#toc">table of
                        contents</a></strong></em><em><strong> below to find the section you are looking
                        for.</strong></em></p><p id="76e7f2a7-0386-404f-8b56-f70d1cd66c77" className=""><strong>What
                        personal information do we process?</strong> When you visit, use, or navigate our Services, we
                        may process personal information depending on how you interact with us and the Services, the
                        choices you make, and the products and features you use. Learn more about <a
                            href="#personalinfo">personal information you disclose to
                            us</a>.</p><p id="fcd422d0-e035-4048-abc7-4c4f3ecd6a4d" className=""><strong>Do we process
                        any sensitive personal information?</strong> We do not process sensitive personal information.
                    </p><p id="c1c66acc-bb33-4e69-9593-4fa213e7910c" className=""><strong>Do we receive any information
                        from third parties?</strong> We do not receive any information from third parties.</p><p
                        id="da805331-5038-47a8-94b2-4084023b9ab3" className=""><strong>How do we process your
                        information?</strong> We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and to comply with law. We
                        may also process your information for other purposes with your consent. We process your
                        information only when we have a valid legal reason to do so. Learn more about <a
                            href="#infouse">how we process your information</a>.</p>
                    <p id="574f17e9-a5e8-474a-b168-51ee679aa8f3" className=""><strong>In what situations and with which
                        types of parties do we share personal information?</strong> We may share information in specific
                        situations and with specific categories of third parties. Learn more about <a
                            href="#whoshare">when and with whom we share your
                            personal information</a>.</p><p id="e6801a6f-368b-455c-a2e1-f0efddaae129" className="">
                        <strong>How do we keep your information safe?</strong> We have organizational and technical
                        processes and procedures in place to protect your personal information. However, no electronic
                        transmission over the internet or information storage technology can be guaranteed to be 100%
                        secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
                        third parties will not be able to defeat our security and improperly collect, access, steal, or
                        modify your information. Learn more about <a
                        href="#infosafe">how we keep your information safe</a>.</p><p
                        id="92d36c43-2c57-49f9-9645-77e7b70a7218" className=""><strong>What are your
                        rights?</strong> Depending on where you are located geographically, the applicable privacy law
                        may mean you have certain rights regarding your personal information. Learn more about <a
                            href="#privacyrights">your privacy rights</a>.</p><p
                        id="72db79f2-5dd4-4193-bef1-5f2fecdf9105" className=""><strong>How do you exercise your
                        rights?</strong> The easiest way to exercise your rights is by submitting a <a
                        href="https://app.termly.io/notify/497c98c5-fe24-4857-9ec7-dd0018818b35">data subject access
                        request</a>, or by contacting us. We will consider and act upon any request in accordance with
                        applicable data protection laws.</p><p id="afae0113-2cae-4fb0-a61e-da892d46b2e4"
                                                               className="">Want to learn more about what we do with any
                        information we collect? <a href="#toc">Review the privacy
                            notice in full</a>.</p><p id="b2694fa1-66dc-4622-9198-cac3a6999986" className=""><strong>TABLE
                        OF CONTENTS</strong></p><p id="999ba2f0-0ad4-4ee5-b0f5-58090cc2ca23" className=""><a
                        href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
                    </p><p id="d1a76fc9-1fde-428d-aa53-35e6f3a41329" className=""><a
                        href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
                    </p><p id="3e1f040a-c68a-4763-8de7-c3d83a7e05b9" className=""><a
                        href="https://app.coolletenky.cz/articles/privacy-policy#legalbases">3. WHAT LEGAL BASES DO WE RELY ON TO
                        PROCESS YOUR PERSONAL INFORMATION?</a></p><p id="a45d374b-a7ce-4b30-a568-b7883d09997f"
                                                                     className=""><a
                        href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR
                        PERSONAL INFORMATION?</a></p><p id="cab66281-8905-4142-9768-28448b1f3cc0" className=""><a
                        href="#cookies">5. DO WE USE COOKIES AND OTHER TRACKING
                        TECHNOLOGIES?</a></p><p id="119dcf4e-ab81-49fc-ba4a-b9b25f25adc6" className=""><a
                        href="#sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL
                        LOGINS?</a></p><p id="7140dab3-b172-43c3-ace6-463582341f75" className=""><a
                        href="#inforetain">7. HOW LONG DO WE KEEP YOUR
                        INFORMATION?</a></p><p id="0f3336d4-4d56-4c24-866b-27a2d20b8465" className=""><a
                        href="#infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                    </p><p id="05b2c8ff-95db-4bfe-a348-18a3b8ffb4a8" className=""><a
                        href="#infominors">9. DO WE COLLECT INFORMATION FROM
                        MINORS?</a></p><p id="5250244e-ff55-431c-91b0-4f03ccec298e" className=""><a
                        href="#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a>
                    </p><p id="93df0afb-94b5-417e-a079-7541fd954d0c" className=""><a
                        href="#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a></p><p
                        id="c4fec056-3ea8-428e-963e-1d597c7cf30a" className=""><a
                        href="#policyupdates">12. DO WE MAKE UPDATES TO THIS
                        NOTICE?</a></p><p id="469eeb49-7183-4f26-baf6-60e0dedc634c" className=""><a
                        href="#contact">13. HOW CAN YOU CONTACT US ABOUT THIS
                        NOTICE?</a></p><p id="91cb1f89-d377-4661-b5b0-ba51aef9f25c" className=""><a
                        href="#request">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
                        DATA WE COLLECT FROM YOU?</a></p><p id="7d0d6fb4-df0e-4045-986e-5f1c170210ff" className="">
                        <strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p><p
                        id="fa381540-cf68-4d32-833b-327efdfb2c68" className=""><strong>Personal information you disclose
                        to us</strong></p><p id="ae2a0bc2-345f-4641-a86e-b676b3b39de4" className=""><em><strong>In
                        Short: </strong></em><em>We collect personal information that you provide to us.</em></p><p
                        id="3c46a434-cda8-46f0-8134-bdec8467e303" className="">We collect personal information that you
                        voluntarily provide to us when you register on the Services, express an interest in obtaining
                        information about us or our products and Services, when you participate in activities on the
                        Services, or otherwise when you contact us.</p><p id="29d219f8-11f9-4533-99f3-c6e5e1a46aaa"
                                                                          className=""><strong>Personal Information
                        Provided by You.</strong> The personal information that we collect depends on the context of
                        your interactions with us and the Services, the choices you make, and the products and features
                        you use. The personal information we collect may include the following:</p>
                    <ul id="867212b8-149a-4c1c-a28b-034f27c3fb1e" className="bulleted-list">
                        <li >names</li>
                    </ul>
                    <ul id="b9ffcca1-d409-4282-8677-89c443a7c22d" className="bulleted-list">
                        <li >phone numbers</li>
                    </ul>
                    <ul id="1c0fa55a-83d5-4291-be68-f7f74b8a98b6" className="bulleted-list">
                        <li >email addresses</li>
                    </ul>
                    <ul id="1d981129-9845-42e9-b217-4b2333cc5e6b" className="bulleted-list">
                        <li >usernames</li>
                    </ul>
                    <ul id="39b6564b-d46f-4803-ac19-95816752c01a" className="bulleted-list">
                        <li >passwords</li>
                    </ul>
                    <ul id="0d5f60ae-c964-40e8-b99d-84096dd10e73" className="bulleted-list">
                        <li >mailing addresses</li>
                    </ul>
                    <ul id="198b5f47-434b-4af3-b571-92570ad3d823" className="bulleted-list">
                        <li >contact preferences</li>
                    </ul>
                    <ul id="caf236a7-87a7-4afa-847a-a7e0de8373b8" className="bulleted-list">
                        <li >job titles</li>
                    </ul>
                    <ul id="e899d773-a921-4ddb-8037-1cea5587cc5e" className="bulleted-list">
                        <li >contact or authentication data</li>
                    </ul>
                    <ul id="69d18b39-dee2-45cf-a025-bb6583b447e3" className="bulleted-list">
                        <li >billing addresses</li>
                    </ul>
                    <p id="80eb30c4-7464-4e94-8ec8-5ad3f3f26594" className=""><strong>Sensitive Information.</strong> We
                        do not process sensitive information.</p><p id="ef5b93ac-d680-46fd-822f-db72fc454072"
                                                                    className=""><strong>Social Media Login
                        Data. </strong>We may provide you with the option to register with us using your existing social
                        media account details, like your Facebook, Twitter, or other social media account. If you choose
                        to register in this way, we will collect the information described in the section called &quot;
                        <a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL
                            LOGINS?</a>&quot; below.</p><p id="907d4239-e057-4d7d-8288-07f097fc8147" className="">All
                        personal information that you provide to us must be true, complete, and accurate, and you must
                        notify us of any changes to such personal information.</p><p
                        id="caa20c79-0fe7-4812-9d78-091866e729c2" className=""><strong>Information automatically
                        collected</strong></p><p id="72ea7503-6acb-424b-92c8-f57c32ebaa3b" className=""><em><strong>In
                        Short: </strong></em><em>Some information — such as your Internet Protocol (IP) address and/or
                        browser and device characteristics — is collected automatically when you visit our
                        Services.</em></p><p id="fa2071d5-f7ac-4ebe-a558-9b9c20954319" className="">We automatically
                        collect certain information when you visit, use, or navigate the Services. This information does
                        not reveal your specific identity (like your name or contact information) but may include device
                        and usage information, such as your IP address, browser and device characteristics, operating
                        system, language preferences, referring URLs, device name, country, location, information about
                        how and when you use our Services, and other technical information. This information is
                        primarily needed to maintain the security and operation of our Services, and for our internal
                        analytics and reporting purposes.</p><p id="6891e8ea-553f-452a-a47c-e345bd6d862a"
                                                                className="">Like many businesses, we also collect
                        information through cookies and similar technologies.</p><p
                        id="c6859957-d09c-4175-9256-80bf025237e4" className="">The information we collect includes:</p>
                    <ul id="d14f97ce-42d8-4ccd-ac73-24712af86f9e" className="bulleted-list">
                        <li ><em>Log and Usage Data.</em> Log and usage data is
                            service-related, diagnostic, usage, and performance information our servers automatically
                            collect when you access or use our Services and which we record in log files. Depending on
                            how you interact with us, this log data may include your IP address, device information,
                            browser type, and settings and information about your activity in the Services (such as the
                            date/time stamps associated with your usage, pages and files viewed, searches, and other
                            actions you take such as which features you use), device event information (such as system
                            activity, error reports (sometimes called &quot;crash dumps&quot;), and hardware settings).
                        </li>
                    </ul>
                    <ul id="5d572922-5181-41ce-8a75-69396c54d9a4" className="bulleted-list">
                        <li ><em>Device Data.</em> We collect device data such as
                            information about your computer, phone, tablet, or other device you use to access the
                            Services. Depending on the device used, this device data may include information such as
                            your IP address (or proxy server), device and application identification numbers, location,
                            browser type, hardware model, Internet service provider and/or mobile carrier, operating
                            system, and system configuration information.
                        </li>
                    </ul>
                    <ul id="2c956b14-03f2-4276-97d5-958722f3f66f" className="bulleted-list">
                        <li ><em>Location Data.</em> We collect location data such as
                            information about your device&#x27;s location, which can be either precise or imprecise. How
                            much information we collect depends on the type and settings of the device you use to access
                            the Services. For example, we may use GPS and other technologies to collect geolocation data
                            that tells us your current location (based on your IP address). You can opt out of allowing
                            us to collect this information either by refusing access to the information or by disabling
                            your Location setting on your device. However, if you choose to opt out, you may not be able
                            to use certain aspects of the Services.
                        </li>
                    </ul>
                    <p id="7a4742c2-26e4-4f37-8f90-045d9cf50875" className=""><strong>2. HOW DO WE PROCESS YOUR
                        INFORMATION?</strong></p><p id="15eef264-4528-4809-8e65-9f78f397d9e6" className=""><em><strong>In
                        Short: </strong></em><em>We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and to comply with law. We
                        may also process your information for other purposes with your consent.</em></p><p
                        id="cc07afc2-7485-43fa-bcc4-a56fe093b7ac" className=""><strong>We process your personal
                        information for a variety of reasons, depending on how you interact with our Services,
                        including:</strong></p>
                    <ul id="48aac3c0-907b-47c9-84a6-360124074bab" className="bulleted-list">
                        <li ><strong>To facilitate account creation and authentication and
                            otherwise manage user accounts. </strong>We may process your information so you can create
                            and log in to your account, as well as keep your account in working order.
                        </li>
                    </ul>
                    <ul id="b3e1a06c-5a19-4cdb-8626-e78e1404ade9" className="bulleted-list">
                        <li ><strong>To deliver and facilitate delivery of services to the
                            user. </strong>We may process your information to provide you with the requested service.
                        </li>
                    </ul>
                    <ul id="5fd1ecd3-d734-4040-8508-fd36d29787ca" className="bulleted-list">
                        <li ><strong>To respond to user inquiries/offer support to
                            users. </strong>We may process your information to respond to your inquiries and solve any
                            potential issues you might have with the requested service.
                        </li>
                    </ul>
                    <ul id="476cc653-2e8d-4422-b08d-bdaf8a3ffc30" className="bulleted-list">
                        <li ><strong>To send administrative information to you. </strong>We
                            may process your information to send you details about our products and services, changes to
                            our terms and policies, and other similar information.
                        </li>
                    </ul>
                    <ul id="5b907154-e62a-43cd-9652-89fac8830b31" className="bulleted-list">
                        <li ><strong>To fulfill and manage your orders.</strong> We may
                            process your information to fulfill and manage your orders, payments, returns, and exchanges
                            made through the Services.
                        </li>
                    </ul>
                    <ul id="68f4cbc4-e5ed-4235-837f-77e67ec494ff" className="bulleted-list">
                        <li ><strong>To enable user-to-user communications. </strong>We may
                            process your information if you choose to use any of our offerings that allow for
                            communication with another user.
                        </li>
                    </ul>
                    <ul id="ebae3c1c-158e-4baa-ac1a-77edc995a1a3" className="bulleted-list">
                        <li ><strong>To request feedback. </strong>We may process your
                            information when necessary to request feedback and to contact you about your use of our
                            Services.
                        </li>
                    </ul>
                    <ul id="e3e9e67d-5bfe-408e-9e3c-7a78e9d0bf84" className="bulleted-list">
                        <li ><strong>To send you marketing and promotional
                            communications. </strong>We may process the personal information you send to us for our
                            marketing purposes, if this is in accordance with your marketing preferences. You can opt
                            out of our marketing emails at any time. For more information, see &quot;<a
                                href="#privacyrights">WHAT ARE YOUR PRIVACY
                                RIGHTS?</a>&quot; below.
                        </li>
                    </ul>
                    <ul id="ad4e6aab-9bf8-499e-9af9-227132c54bcf" className="bulleted-list">
                        <li ><strong>To protect our Services.</strong> We may process your
                            information as part of our efforts to keep our Services safe and secure, including fraud
                            monitoring and prevention.
                        </li>
                    </ul>
                    <ul id="95c06c3c-9b93-4037-9857-6abe4b5a4bbc" className="bulleted-list">
                        <li ><strong>To identify usage trends.</strong> We may process
                            information about how you use our Services to better understand how they are being used so
                            we can improve them.
                        </li>
                    </ul>
                    <ul id="e7ee1704-dd22-4d75-a954-b678dac0ea1e" className="bulleted-list">
                        <li ><strong>To save or protect an individual&#x27;s vital
                            interest.</strong> We may process your information when necessary to save or protect an
                            individual’s vital interest, such as to prevent harm.
                        </li>
                    </ul>
                    <p id="e4d72049-674e-4575-aaee-06fe6391f600" className=""><strong>3. WHAT LEGAL BASES DO WE RELY ON
                        TO PROCESS YOUR INFORMATION?</strong></p><p id="d71dd4eb-adf2-4eac-b9df-ccb6c40c9e5d"
                                                                    className=""><em><strong>In
                        Short: </strong></em><em>We only process your personal information when we believe it is
                        necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law,
                        like with your consent, to comply with laws, to provide you with services to enter into or
                        fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate
                        business interests.</em></p><p id="b8cffabd-c3b1-457d-8fb2-d4281eb16d48" className="">The
                        General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                        bases we rely on in order to process your personal information. As such, we may rely on the
                        following legal bases to process your personal information:</p>
                    <ul id="884588e9-1661-486c-afcd-c09588dee993" className="bulleted-list">
                        <li ><strong>Consent. </strong>We may process your information if
                            you have given us permission (i.e., consent) to use your personal information for a specific
                            purpose. You can withdraw your consent at any time. Learn more about <a
                                href="#withdrawconsent">withdrawing your consent</a>.
                        </li>
                    </ul>
                    <ul id="3c151aff-2aa1-4dfc-b668-1818d97fbab2" className="bulleted-list">
                        <li ><strong>Performance of a Contract.</strong> We may process your
                            personal information when we believe it is necessary to fulfill our contractual obligations
                            to you, including providing our Services or at your request prior to entering into a
                            contract with you.
                        </li>
                    </ul>
                    <ul id="9d5301c8-a79e-47d7-8879-7d404f988613" className="bulleted-list">
                        <li ><strong>Legitimate Interests.</strong> We may process your
                            information when we believe it is reasonably necessary to achieve our legitimate business
                            interests and those interests do not outweigh your interests and fundamental rights and
                            freedoms. For example, we may process your personal information for some of the purposes
                            described in order to:
                        </li>
                    </ul>
                    <ul id="7af61335-3512-4904-a924-af87a32f7426" className="bulleted-list">
                        <li >Send users information about special offers and discounts on
                            our products and services
                        </li>
                    </ul>
                    <ul id="1180b263-0ace-4afb-b45f-ab5dc57142a3" className="bulleted-list">
                        <li >Analyze how our Services are used so we can improve them to
                            engage and retain users
                        </li>
                    </ul>
                    <ul id="fbf6c05e-cb30-41ed-b189-9b5170035d3d" className="bulleted-list">
                        <li >Diagnose problems and/or prevent fraudulent activities</li>
                    </ul>
                    <ul id="9bd08329-8ad3-4685-9561-6370e9ba5e21" className="bulleted-list">
                        <li >Understand how our users use our products and services so we
                            can improve user experience
                        </li>
                    </ul>
                    <ul id="a42bc3e8-530c-41a3-8b1c-dd6dfbcf3e54" className="bulleted-list">
                        <li ><strong>Legal Obligations.</strong> We may process your
                            information where we believe it is necessary for compliance with our legal obligations, such
                            as to cooperate with a law enforcement body or regulatory agency, exercise or defend our
                            legal rights, or disclose your information as evidence in litigation in which we are
                            involved.
                        </li>
                    </ul>
                    <ul id="a073a61a-5a71-4175-af7d-7510c2ebc5d3" className="bulleted-list">
                        <li ><strong>Vital Interests.</strong> We may process your
                            information where we believe it is necessary to protect your vital interests or the vital
                            interests of a third party, such as situations involving potential threats to the safety of
                            any person.
                        </li>
                    </ul>
                    <p id="bf32d4ce-9c5e-4de0-9161-755997285ac3" className=""><strong>4. WHEN AND WITH WHOM DO WE SHARE
                        YOUR PERSONAL INFORMATION?</strong></p><p id="0ffba170-92b4-4e5d-92b4-21149d80177a"
                                                                  className=""><em><strong>In
                        Short:</strong></em><em> We may share information in specific situations described in this
                        section and/or with the following categories of third parties.</em></p><p
                        id="2fc0067b-2618-4d13-8eb2-ec98f7c41591" className=""><strong>Vendors, Consultants, and Other
                        Third-Party Service Providers.</strong> We may share your data with third-party vendors, service
                        providers, contractors, or agents (&quot;<strong>third parties</strong>&quot;) who perform
                        services for us or on our behalf and require access to such information to do that work. We have
                        contracts in place with our third parties, which are designed to help safeguard your personal
                        information. This means that they cannot do anything with your personal information unless we
                        have instructed them to do it. They will also not share your personal information with any
                        organization apart from us. They also commit to protect the data they hold on our behalf and to
                        retain it for the period we instruct. The categories of third parties we may share personal
                        information with are as follows:</p>
                    <ul id="ce80c4a0-1e39-4c57-8242-b0f3b23c0623" className="bulleted-list">
                        <li >Affiliate Marketing Programs</li>
                    </ul>
                    <ul id="a7e4bb61-fdf9-42d7-98a1-1b498c72ef21" className="bulleted-list">
                        <li >Payment Processors</li>
                    </ul>
                    <ul id="b4369636-b795-4456-907d-33ba98c83594" className="bulleted-list">
                        <li >Retargeting Platforms</li>
                    </ul>
                    <ul id="eabfa93f-4ef5-4d36-b7b8-45ea68d5512a" className="bulleted-list">
                        <li >Social Networks</li>
                    </ul>
                    <ul id="78a4c5d1-ed42-4771-b14f-2309150bbf1b" className="bulleted-list">
                        <li >User Account Registration &amp; Authentication Services</li>
                    </ul>
                    <ul id="16312dd4-702a-401d-a5d2-d5a15f5251e4" className="bulleted-list">
                        <li >Cloud Computing Services</li>
                    </ul>
                    <ul id="5af3aa86-d8bb-46f5-9bbe-c37b3ae5ac27" className="bulleted-list">
                        <li >Data Analytics Services</li>
                    </ul>
                    <p id="f92bf66d-429d-456f-9b8b-1aca36f9a095" className="">We also may need to share your personal
                        information in the following situations:</p>
                    <ul id="5e9e85df-12f1-4320-834a-d17163285717" className="bulleted-list">
                        <li ><strong>Business Transfers.</strong> We may share or transfer
                            your information in connection with, or during negotiations of, any merger, sale of company
                            assets, financing, or acquisition of all or a portion of our business to another company.
                        </li>
                    </ul>
                    <ul id="615d7981-a68f-4170-b882-6c3f48136a9d" className="bulleted-list">
                        <li ><strong>When we use Google Analytics.</strong> We may share
                            your information with Google Analytics to track and analyze the use of the Services. The
                            Google Analytics Advertising Features that we may use include: Remarketing with Google
                            Analytics, Google Analytics Demographics and Interests Reporting and Google Display Network
                            Impressions Reporting. To opt out of being tracked by Google Analytics across the Services,
                            visit <a
                                href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                            You can opt out of Google Analytics Advertising Features through <a
                                href="https://adssettings.google.com/">Ads Settings</a> and Ad Settings for mobile apps.
                            Other opt out means include <a
                                href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a> and <a
                                href="http://www.networkadvertising.org/mobile-choice">http://www.networkadvertising.org/mobile-choice</a>.
                            For more information on the privacy practices of Google, please visit the <a
                                href="https://policies.google.com/privacy">Google Privacy &amp; Terms page</a>.
                        </li>
                    </ul>
                    <ul id="fd3c8790-f906-45aa-b174-5d012d83b0bc" className="bulleted-list">
                        <li ><strong>When we use Google Maps Platform APIs.</strong> We may
                            share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places
                            API). We obtain and store on your device (&quot;cache&quot;) your location. You may revoke
                            your consent anytime by contacting us at the contact details provided at the end of this
                            document.
                        </li>
                    </ul>
                    <p id="fe2b745b-a4ca-46fd-aa0b-7a3aeaf917c9" className=""><strong>5. DO WE USE COOKIES AND OTHER
                        TRACKING TECHNOLOGIES?</strong></p><p id="85555795-0637-4ebd-9f1e-73ae70235076" className="">
                        <em><strong>In Short:</strong></em><em> We may use cookies and other tracking technologies to
                        collect and store your information.</em></p><p id="3f007d54-5a5b-41d9-8d34-23b96c13b19f"
                                                                       className="">We may use cookies and similar
                        tracking technologies (like web beacons and pixels) to access or store information. Specific
                        information about how we use such technologies and how you can refuse certain cookies is set out
                        in our Cookie Notice.</p><p id="08b37e88-f7cb-4349-9a18-08a23c160731" className=""><strong>6.
                        HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></p><p id="91aa1c06-6ca7-47f1-8412-13d9d5521136"
                                                                            className=""><em><strong>In Short: </strong></em><em>If
                        you choose to register or log in to our Services using a social media account, we may have
                        access to certain information about you.</em></p><p id="c5963f47-a52f-40d5-afd9-faaaef5aa7a8"
                                                                            className="">Our Services offer you the
                        ability to register and log in using your third-party social media account details (like your
                        Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
                        information about you from your social media provider. The profile information we receive may
                        vary depending on the social media provider concerned, but will often include your name, email
                        address, friends list, and profile picture, as well as other information you choose to make
                        public on such a social media platform.</p><p id="a353eae0-0e4e-4969-b08a-1ca0b4419ebd"
                                                                      className="">We will use the information we
                        receive only for the purposes that are described in this privacy notice or that are otherwise
                        made clear to you on the relevant Services. Please note that we do not control, and are not
                        responsible for, other uses of your personal information by your third-party social media
                        provider. We recommend that you review their privacy notice to understand how they collect, use,
                        and share your personal information, and how you can set your privacy preferences on their sites
                        and apps.</p><p id="7a32cc28-c849-41cc-a4df-39ab7d2a5f7e" className=""><strong>7. HOW LONG DO WE
                        KEEP YOUR INFORMATION?</strong></p><p id="577dff6d-b880-4f6d-a000-7411d88a1019" className="">
                        <em><strong>In Short: </strong></em><em>We keep your information for as long as necessary to
                        fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em></p>
                    <p id="311d46f1-6426-45f9-a9dd-0f64adb1b81b" className="">We will only keep your personal
                        information for as long as it is necessary for the purposes set out in this privacy notice,
                        unless a longer retention period is required or permitted by law (such as tax, accounting, or
                        other legal requirements). No purpose in this notice will require us keeping your personal
                        information for longer than three (3) months past the termination of the user&#x27;s
                        account.</p><p id="664f9077-29ba-4532-8969-7a5e7bc402bd" className="">When we have no ongoing
                        legitimate business need to process your personal information, we will either delete or
                        anonymize such information, or, if this is not possible (for example, because your personal
                        information has been stored in backup archives), then we will securely store your personal
                        information and isolate it from any further processing until deletion is possible.</p><p
                        id="b802ba0e-9ea5-4ea6-8174-ee77817fb31b" className=""><strong>8. HOW DO WE KEEP YOUR
                        INFORMATION SAFE?</strong></p><p id="dd747d9b-5442-4d97-8fb7-4ae6e91ee84b" className="">
                        <em><strong>In Short: </strong></em><em>We aim to protect your personal information through a
                        system of organizational and technical security measures.</em></p><p
                        id="702b239c-1542-4da7-9086-a144384c139c" className="">We have implemented appropriate and
                        reasonable technical and organizational security measures designed to protect the security of
                        any personal information we process. However, despite our safeguards and efforts to secure your
                        information, no electronic transmission over the Internet or information storage technology can
                        be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals,
                        or other unauthorized third parties will not be able to defeat our security and improperly
                        collect, access, steal, or modify your information. Although we will do our best to protect your
                        personal information, transmission of personal information to and from our Services is at your
                        own risk. You should only access the Services within a secure environment.</p><p
                        id="db9d01bd-0a77-4903-9402-be57bf4af538" className=""><strong>9. DO WE COLLECT INFORMATION FROM
                        MINORS?</strong></p><p id="ad521c24-55e6-47ab-8a50-c684cebee46c" className=""><em><strong>In
                        Short:</strong></em><em> We do not knowingly collect data from or market to children under 18
                        years of age.</em></p><p id="55e4a91d-93e7-4d67-b1f0-251b8b8d053f" className="">We do not
                        knowingly solicit data from or market to children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are the parent or guardian of such a minor
                        and consent to such minor dependent’s use of the Services. If we learn that personal information
                        from users less than 18 years of age has been collected, we will deactivate the account and take
                        reasonable measures to promptly delete such data from our records. If you become aware of any
                        data we may have collected from children under age 18, please contact us at
                        privacy@coolletenky.cz.</p><p id="a7ab5787-6ce6-465a-978a-073629866a24" className=""><strong>10.
                        WHAT ARE YOUR PRIVACY RIGHTS?</strong></p><p id="18cb5ba2-fdf9-41cd-8666-1434e6323e9f"
                                                                     className=""><em><strong>In
                        Short:</strong></em><em> In some regions, such as the European Economic Area (EEA), United
                        Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control over
                        your personal information. You may review, change, or terminate your account at any time.</em>
                    </p><p id="759b207d-8680-47cf-81cc-80dcc57a851e" className="">In some regions (like the EEA, UK, and
                        Switzerland), you have certain rights under applicable data protection laws. These may include
                        the right (i) to request access and obtain a copy of your personal information, (ii) to request
                        rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if
                        applicable, to data portability; and (v) not to be subject to automated decision-making. In
                        certain circumstances, you may also have the right to object to the processing of your personal
                        information. You can make such a request by contacting us by using the contact details provided
                        in the section &quot;<a href="#contact">HOW CAN YOU CONTACT
                            US ABOUT THIS NOTICE?</a>&quot; below.</p><p id="a08cac72-2274-431b-891f-6b35e5155b26"
                                                                         className="">We will consider and act upon any
                        request in accordance with applicable data protection laws.</p><p
                        id="475748cd-3ebf-464f-9289-ad5ceac0c021" className="">If you are located in the EEA or UK and
                        you believe we are unlawfully processing your personal information, you also have the right to
                        complain to your <a
                            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">Member
                            State data protection authority</a> or <a
                            href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK
                            data protection authority</a>.</p><p id="5020265f-f4a5-42e2-ad7c-c845ea9f9823"
                                                                 className="">If you are located in Switzerland, you may
                        contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and
                            Information Commissioner</a>.</p><p id="624505a2-c76c-4c2f-8632-f35f9796e955" className="">
                        <strong><span >Withdrawing your consent:</span></strong> If we
                        are relying on your consent to process your personal information, you have the right to withdraw
                        your consent at any time. You can withdraw your consent at any time by contacting us by using
                        the contact details provided in the section &quot;<a
                        href="#contact">HOW CAN YOU CONTACT US ABOUT THIS
                        NOTICE?</a>&quot; below or updating your preferences.</p><p
                        id="f005ab10-9347-439a-b8e5-db68eb6bd10f" className="">However, please note that this will not
                        affect the lawfulness of the processing before its withdrawal nor, will it affect the processing
                        of your personal information conducted in reliance on lawful processing grounds other than
                        consent.</p><p id="549c0ddb-f7cc-4201-8201-bc73f1b3ec36" className=""><strong><span
                        >Opting out of marketing and promotional communications: </span></strong>You
                        can unsubscribe from our marketing and promotional communications at any time by clicking on the
                        unsubscribe link in the emails that we send, or by contacting us using the details provided in
                        the section &quot;<a href="#contact">HOW CAN YOU CONTACT US
                            ABOUT THIS NOTICE?</a>&quot; below. You will then be removed from the marketing lists.
                        However, we may still communicate with you — for example, to send you service-related messages
                        that are necessary for the administration and use of your account, to respond to service
                        requests, or for other non-marketing purposes.</p><p id="b1f364be-c075-483b-9f99-b9d5f5f27a48"
                                                                             className=""><strong>Account
                        Information</strong></p><p id="95f45037-aa3e-4bb5-bbec-5997bdf6af81" className="">If you would
                        at any time like to review or change the information in your account or terminate your account,
                        you can:</p>
                    <ul id="22c95a16-e112-445b-9a0d-48d555c11d7f" className="bulleted-list">
                        <li >Log in to your account settings and update your user account.
                        </li>
                    </ul>
                    <p id="061fc953-01d8-4b70-be2c-5f4f9f177b2e" className="">Upon your request to terminate your
                        account, we will deactivate or delete your account and information from our active databases.
                        However, we may retain some information in our files to prevent fraud, troubleshoot problems,
                        assist with any investigations, enforce our legal terms and/or comply with applicable legal
                        requirements.</p><p id="37778044-d740-4eec-b84a-b2893f6a3546" className=""><strong><span
                        >Cookies and similar technologies:</span></strong> Most Web
                        browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
                        browser to remove cookies and to reject cookies. If you choose to remove cookies or reject
                        cookies, this could affect certain features or services of our Services.</p><p
                        id="19f82fab-a1b1-4dba-a431-fec96d902c0a" className="">If you have questions or comments about
                        your privacy rights, you may email us at privacy@coolletenky.cz.</p><p
                        id="92beb629-b73a-41a4-bc94-54edc2479cab" className=""><strong>11. CONTROLS FOR DO-NOT-TRACK
                        FEATURES</strong></p><p id="87507ff4-f336-43e9-9f20-703a4becd689" className="">Most web browsers
                        and some mobile operating systems and mobile applications include a Do-Not-Track
                        (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to
                        have data about your online browsing activities monitored and collected. At this stage no
                        uniform technology standard for recognizing and implementing DNT signals has been finalized. As
                        such, we do not currently respond to DNT browser signals or any other mechanism that
                        automatically communicates your choice not to be tracked online. If a standard for online
                        tracking is adopted that we must follow in the future, we will inform you about that practice in
                        a revised version of this privacy notice.</p><p id="c2349739-bedd-4fbc-a9e2-f02b949902c8"
                                                                        className=""><strong>12. DO WE MAKE UPDATES TO
                        THIS NOTICE?</strong></p><p id="bac47606-ae8d-45f8-af52-fa4db4c2f143" className=""><em><strong>In
                        Short: </strong></em><em>Yes, we will update this notice as necessary to stay compliant with
                        relevant laws.</em></p><p id="24384f01-8816-4d8e-b173-9c92b4152100" className="">We may update
                        this privacy notice from time to time. The updated version will be indicated by an
                        updated &quot;Revised&quot; date and the updated version will be effective as soon as it is
                        accessible. If we make material changes to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by directly sending you a notification. We
                        encourage you to review this privacy notice frequently to be informed of how we are protecting
                        your information.</p><p id="301feb6d-fdd5-4302-b740-b50efd2acdb2" className=""><strong>13. HOW
                        CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></p><p id="91b62051-23da-46f1-adcf-a1174b32ec31"
                                                                             className="">If you have questions or
                        comments about this notice, you may email us at privacy@coolletenky.cz or contact us by post
                        at:</p><p id="3dc1307b-58a0-4193-92a3-aa8f79a4d922" className="">Lukáš Bauer<br/>Gen. Svobody 70<br/>Kravaře
                        471 03<br/>Czech Republic<br/></p><p id="ea9c745c-6add-4ab7-b77f-4a3626bc8deb" className="">
                        <strong>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></p><p
                        id="2d4537cb-7b16-4c7e-883b-9971c03fa320" className="">Based on the applicable laws of your
                        country, you may have the right to request access to the personal information we collect from
                        you, change that information, or delete it. To request to review, update, or delete your
                        personal information, please fill out and submit a <a
                            href="https://app.termly.io/notify/497c98c5-fe24-4857-9ec7-dd0018818b35">data subject access
                            request</a>.</p><p id="cfc63c2c-5822-4e32-ab28-0dd75ce06932" className="">
                    </p></div>
            </article>
        </Container>
    )
}