const dateFormat = new Intl.DateTimeFormat('cs-CZ', {
    year: 'numeric', month: 'long', day: 'numeric',
    timeZone: 'UTC'
});

const timeFormat = new Intl.DateTimeFormat('cs-CZ', {
    hour: "numeric", minute: "numeric",
    timeZone: 'UTC'
});

export function formatDateTime(s: Date) {
    const now = new Date();
    if (s.getDay() === now.getDay()) {
        return timeFormat.format(s) + " Dnes";
    }
    return timeFormat.format(s) + " " + dateFormat.format(s);
}

export function formatDate(s: Date|string) {
    if(typeof s == "string"){
        return s
    }
    return dateFormat.format(s);
}

