import {ConstantsContext} from "../../provider/ConstantsProvider";
import {useContext} from "react";

interface Props {
    airportCode: string
}
export default function Airport(props: Props) {
    const { airportCode } = props;
    const {airports: Airports} = useContext(ConstantsContext);
    const value = Airports.find((country) => country.code === airportCode?.toUpperCase())
    return <span title={value?.name}> {airportCode} </span>
}