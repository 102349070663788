import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import * as React from "react";
import {useUserProfile} from "../../hooks/useUserProfile";
import IconButton from "@mui/material/IconButton";
import {AccountCircle} from "@mui/icons-material";
import {Menu, MenuItem} from "@mui/material";

export default function UserMenu(){
    const {session} = useUserProfile();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if(!session?.user || !session){
        return <Button color="inherit" component={Link} to={'/profile'}>Přihlásit Se</Button>
    }

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to={"#"}>{session.user.email}</MenuItem>
                <MenuItem component={Link} to={"/profile/sign-out"}>Odhlásit se</MenuItem>
            </Menu>
        </>
    );
}