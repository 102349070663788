import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {createTheme, CssBaseline} from "@mui/material";
import {Outlet} from "react-router-dom";
import BottomNav from "./BottomNav";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${props => props.theme.primary1};
`

const theme = createTheme();

export default function Root(){
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ContentWrapper>
                <Outlet/>
            </ContentWrapper>
            <BottomNav/>
        </ThemeProvider>
    )
}