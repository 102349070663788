import AppHeader from "../components/UI/Molecules/AppHeader";
import {SideBarTemplate} from "../components/UI/Atoms/SideBarTemplate";
import {SideBar} from "../components/HomePage/Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import {Divider, Grid, List, Toolbar} from "@mui/material";
import {SidebarListItem} from "../components/UI/Molecules/SidebarListItem";

export default function Articles(){
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                <SidebarListItem text={'Privacy Policy'} icon={<VpnLockIcon/>} href={'/articles/privacy-policy'}/>
            </List>
        </div>
    );

    const icon = (
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: 'none' }}}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
            <MenuIcon />
        </IconButton>
    );

    return (
        <>
            <AppHeader icon={icon} title={"Informace"}/>
            <SideBarTemplate>
                <SideBar
                    drawer={drawer}
                    isOpen={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    onOpen={() => setIsSidebarOpen(true)}
                />
                <Grid container spacing={2} padding={2} paddingBottom={10} id={"salam"} alignItems={"center"} justifyContent={"space-around"}>
                Vyberte článek
            </Grid>;
            </SideBarTemplate>
        </>
    )
}