import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {useMutation} from "@apollo/client";
import {removeFilter} from "../../../query/mutateFilter";
import loadFilters from "../../../query/loadFilters";
import {useSnackbar} from "notistack";


type SidebarListProps = {
    id?: string
    text: string,
    icon: JSX.Element
    href: string
    selected?: boolean
    onPress?: () => void
    isPublic?: boolean
};

export const SidebarListItem = (props: SidebarListProps) => {
    const  { id,  text, icon, href, selected, onPress} = props;
    const [ removeFilterMutation ] = useMutation(removeFilter)
    const { enqueueSnackbar } = useSnackbar();
    const isPublic = props.isPublic === undefined || props.isPublic
    const handleRemoveFilter = () => {
        removeFilterMutation({
            variables: {
                id: id
            },
            refetchQueries:[
                loadFilters
            ]
        }).then(() => {
            enqueueSnackbar("Filtr byl odstraněn", {
                variant: "success",
                preventDuplicate: true
            });
        })
    };
    return (
        <ListItem
            key={text}
            disablePadding
            onClick={onPress}
            secondaryAction={
                !isPublic ? (<IconButton aria-label="comment">
                    <DeleteIcon onClick={handleRemoveFilter} />
                </IconButton>) : null
            }
        >
            <ListItemButton selected={selected} component={Link} to={href}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text}/>
            </ListItemButton>
        </ListItem>
    )
};