import React from "react";
import {useIntersection} from "../../hooks/useIntersection";
import {Divider, Grid} from "@mui/material";

type Props = {
    onInViewport: () => void
}

export default function TriggerWhenInViewport(props: Props) {
    const {onInViewport} = props;
    const triggerRef = React.useRef(null);
    const isVisible = useIntersection(triggerRef, "0px");

    React.useEffect(() => {
        if (isVisible) {
            onInViewport(); // Trigger a function when the div is visible on view port
        }
    }, [onInViewport, isVisible]);

    return (
        <Grid item xs={12}><Divider ref={triggerRef}>End of list</Divider></Grid>
    )
}