import {gql} from "@apollo/client";

export type ActionType = {
    id: string
    fromCountry: string
    fromAirport: string
    toCountry: string
    toAirport: string
    carrier: string

    text: string
    url: string
    originalTitle: string
    dateFrom: number
    dateTo: number
    price: number
    priceCurrency: string
    accommodationIncluded: boolean
    transferIncluded: boolean
    isHotel: boolean

    isLiked: boolean
    createdAt: number
}

export type ActionQueryResult = {
    actions: Array<ActionType>
}

export default gql`
  query($time: Int, $filterId: ID, $liked:Boolean, $quickFilter: QuickFilterInput) {
    actions(limit: 20, createdBefore: $time, filterId: $filterId, quickFilter: $quickFilter, liked: $liked) {
        id
        fromCountry
        fromAirport
        toCountry
        toAirport
        carrier
    
        text
        url
        originalTitle
        dateFrom
        dateTo
        price
        priceCurrency
        accommodationIncluded
        transferIncluded
        isHotel
        
        isLiked
        createdAt
    }
  }
`;