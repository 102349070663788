import {gql} from "@apollo/client";

export type FilterType = {
    id: string
    name: string
    priceFrom: number
    priceTo: number
    priceCurrency: string
    fromCountry: string
    fromAirport: string
    toCountry: string
    toAirport: string
    departureDateFrom: number
    departureDateTo: number
    returnDateFrom: number
    returnDateTo: number
    isPublic: boolean
    notification: boolean
}

export default gql`
    query{
      filters{
        id
        name
        priceFrom
        priceTo
        priceCurrency
        
        fromCountry
        fromAirport
        toCountry
        toAirport
            
        departureDateFrom
        departureDateTo
        returnDateFrom
        returnDateTo
        
        isPublic
        notification
      }
    }
`;