import React from "react";
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FeedIcon from '@mui/icons-material/Feed';

export default function BottomNav() {
    const location = useLocation();
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1300 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={location.pathname}
            >
                <BottomNavigationAction component={Link} to={"/"} label="Nejnovější" value={"/"} icon={<HomeIcon />}/>
                <BottomNavigationAction component={Link} to={"/favorites"} label="Oblíbené" value={"/favorites"} icon={<FavoriteIcon />} />
                <BottomNavigationAction component={Link} to={"/articles"} label="Informace" value={"/articles"} icon={<FeedIcon />} />
            </BottomNavigation>
        </Paper>
    )
}