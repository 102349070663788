import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import supabase from "../auth/supabase";
import {Session} from "@supabase/supabase-js";


export type UserProfile = {
    id: string
    isLogged: boolean,
    email?: string
    isVerified: boolean
}

export const useUserProfile = () => {
    const  [session, setSession] = useState<Session|null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const logOut = () => {
        supabase.auth.signOut().then(() => {
            enqueueSnackbar("Byl jste uspěšně odhlášen", {
                variant: "success",
                preventDuplicate: true
            });
        })
    }

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    return {
        logOut: logOut,
        session: session,
    };
}