import * as React from "react";
import {useUserProfile} from "../../hooks/useUserProfile";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Auth} from "@supabase/auth-ui-react";
import supabase from "../../auth/supabase";
import {ThemeSupa} from "@supabase/auth-ui-shared";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function Profile(){
    const {t} = useTranslation();
    const {session} = useUserProfile();
    const navigate = useNavigate();

    if(!session?.user){
        return (
            <Container maxWidth={"xs"}>
                <Typography padding={2} variant={"h4"}>{t("user-account")}</Typography>
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    providers={['google']}

                />
            </Container>
        );
    }
    navigate('/');

    const activateNotification = () => {
        if ('Notification' in window && Notification.permission !== 'granted') {
            console.log('Ask user permission')
            Notification.requestPermission(status => {
                console.log('Status:'+status)
            });
        }
        setTimeout(() => displayNotification('Notification Enabled'), 2000)


        const displayNotification = (notificationTitle: string) => {
            if (Notification.permission === 'granted') {
                navigator.serviceWorker.getRegistration().then((reg) => {
                    console.log('trada')
                    console.log(reg)
                    const options = {
                        body: 'Thanks for allowing push notification !',
                        icon:  '/assets/icons/icon-512x512.png',
                        vibrate: [100, 50, 100],
                        data: {
                            dateOfArrival: Date.now(),
                            primaryKey: 0
                        }
                    };
                    if(typeof reg !== 'undefined'){
                        reg.showNotification(notificationTitle, options).then(result => console.log(result));
                    }
                });
            }
        };
    }

    return <div>
        <Button onClick={() => activateNotification()}>Aktivovat Notifikace</Button>
    </div>
}