import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useContext} from "react";
import {ConstantsContext} from "../../../provider/ConstantsProvider";

type Props = {
    label?: string
    airportCode?: string|null
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void
};


export default function AirportSelect(props: Props) {
    const {airportCode, label, onChange} = props;
    const {airports} = useContext(ConstantsContext);
    const value = airports.find((country) => country.code === airportCode?.toUpperCase())
    return <Autocomplete
        {...props}
        value={value??null}
        autoHighlight
        options={airports}
        getOptionLabel={(option) => option.name}
        sx={{ flexGrow: 1 }}
        renderInput={(params) => <TextField variant={"filled"} {...params} label={label} />}
        onChange={onChange}
    />
}