import React, { useEffect, useState } from 'react'
import getApolloClient from "./apollo/apolloClient";
import {App} from "./App";
import {ApolloClient} from "@apollo/client";

export default function Container() {
  const [client, setClient] = useState<ApolloClient<any>|null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getApolloClient().then((client) => {
      setClient(client)
      setLoading(false)
    })
  }, [])

  return <App client={client} loading={loading} />
}