import {Badge, List, ListSubheader} from "@mui/material";
import {SidebarListItem} from "../UI/Molecules/SidebarListItem";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React from "react";
import {useQuery} from "@apollo/client";
import loadFilters, {FilterType} from "../../query/loadFilters";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';

type Props = {
    activeFilter?: string
    toCountryCode?: string
}

export default function FilterList(props: Props) {
    const {t} = useTranslation();
    const {activeFilter, toCountryCode} = props;
    const [_, setSearchParams] = useSearchParams();
    const { data } = useQuery(loadFilters, {
        fetchPolicy: 'cache-and-network'
    });

    if(typeof data === 'undefined'){
        return null;
    }

    const applyFilter = (filter: FilterType) => {
        const newSearchParams = new URLSearchParams();

        if(filter.fromCountry !== null){
            newSearchParams.set("fromCountry", filter.fromCountry);
        }
        if(filter.fromAirport !== null){
            newSearchParams.set("fromAirport", filter.fromAirport);
        }
        if(filter.toCountry !== null){
            newSearchParams.set("toCountry", filter.toCountry);
        }
        if(filter.toAirport !== null){
            newSearchParams.set("toAirport", filter.toAirport);
        }
        if(filter.priceFrom !== null){
            newSearchParams.set("priceFrom", filter.priceFrom.toString());
        }
        if(filter.priceTo !== null){
            newSearchParams.set("priceTo", filter.priceTo.toString());
        }
        if(filter.priceCurrency !== null){
            newSearchParams.set("priceCurrency", filter.priceCurrency);
        }
        if(filter.departureDateFrom !== null){
            newSearchParams.set("departureDateFrom", filter.departureDateFrom.toString());
        }
        if(filter.departureDateTo !== null){
            newSearchParams.set("departureDateTo", filter.departureDateTo.toString());
        }
        if(filter.returnDateFrom !== null){
            newSearchParams.set("returnDateFrom", filter.returnDateFrom.toString());
        }
        if(filter.returnDateTo !== null){
            newSearchParams.set("returnDateTo", filter.returnDateTo.toString());
        }

        console.log(filter)

        setSearchParams(newSearchParams);
    }

    const getFilters = () => {
        return data.filters.map((item: FilterType) => {
            let icon = (
                <Badge color="secondary" variant="dot" invisible={true}>
                    <FilterAltIcon/>
                </Badge>
            );

            if(item.notification){
                icon = (
                    <Badge color="secondary" variant="dot" invisible={true}>
                        <NotificationsIcon/>
                    </Badge>
                )
            }

            return <SidebarListItem
                key={item.id}
                id={item.id}
                selected={activeFilter === item.id}
                text={item.name}
                icon={icon}
                href={`#`}
                onPress={() => applyFilter(item)}
                isPublic={item.isPublic}
            />
        })
    }

    return (
        <>
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        {t("custom-filters")}
                    </ListSubheader>
                }
            >
                <SidebarListItem
                    selected={activeFilter === undefined && toCountryCode === undefined}
                    text={'Všechny akce'}
                    id={""}
                    icon={
                        <Badge color="secondary" variant="dot" invisible={true}>
                            <FilterAltOffIcon/>
                        </Badge>
                    }
                    href={'/'}
                    isPublic={true}
                />
                {getFilters()}
            </List>
        </>
    )
}