import React from "react";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    useRouteError
} from "react-router-dom";
import Root from "./components/root";
import HomePage from "./routes/HomePage";
import {ApolloClient, ApolloProvider} from "@apollo/client";
import {Backdrop, CircularProgress, Grid} from "@mui/material";
import SignOut from "./routes/profile/SignOut";
import Profile from "./routes/profile/Profile";
import BottomNav from "./components/BottomNav";
import Typography from "@mui/material/Typography";
import Favorites from "./routes/Favorites";
import Articles from "./routes/Articles";
import {SnackbarProvider} from "notistack";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {ConstantsProvider} from "./provider/ConstantsProvider";
import PrivacyPolicy from "./routes/articles/PrivacyPolicy";

type AppProps = {
    client: ApolloClient<any>|null
    loading: boolean
}

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return (
        <>
            <Grid container direction={"column"}  alignItems="center" justifyContent={"space-around"} height={"100%"}>
                <div>
                    <Typography variant={"h2"}>404</Typography>
                    <Typography variant={"body2"}>Page not found</Typography>
                </div>
            </Grid>
            <BottomNav/>
        </>
    )
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root/>} errorElement={<ErrorBoundary />}>
            <Route path={""}>
                <Route index element={<HomePage/>}/>
                <Route path={"filter/:filterId"} element={<HomePage/>}/>
                <Route path={"to-country/:toCountryCode"} element={<HomePage/>}/>
                <Route path={"articles"}>
                    <Route index element={<Articles/>}/>
                    <Route path={"privacy-policy"} element={<PrivacyPolicy/>} />
                </Route>
                <Route path={"favorites"} element={<Favorites />}/>
                <Route path={"profile"}>
                    <Route index element={<Profile/>}/>
                    <Route path={"sign-out"} element={<SignOut/>} />
                </Route>
            </Route>
        </Route>
    )
);

export function App(props: AppProps){
    const {client, loading} = props;
    if(loading || client === null){
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ConstantsProvider>
            <ApolloProvider client={client}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <RouterProvider router={router}/>
                </SnackbarProvider>
            </ApolloProvider>
            </ConstantsProvider>
        </LocalizationProvider>
    )

}