import {gql} from "@apollo/client";

export default gql`
mutation createFilter ($filter: FilterInput!){
    CreateFilter(input: $filter){
        id
    }
}`;

export const removeFilter = gql`
    mutation removeFilter($id: ID){
        RemoveFilter(id: $id)
    }
`