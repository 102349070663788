import React from "react";
import List from "../components/ActionsList/List";
import {useTranslation} from "react-i18next";
import AppHeader from "../components/UI/Molecules/AppHeader";

export default function Favorites(){
    const {t} = useTranslation();
    return (
        <>
            <AppHeader icon={<></>} title={"Oblíbené Akce"}/>
            <List filterLiked={true}/>
        </>
    )
}