import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useContext, useEffect} from "react";
import {ConstantsContext} from "../../../provider/ConstantsProvider";

type Props = {
    label?: string
    countryCode?: string|null
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void
};

export default function CountrySelect(props: Props) {
    const {countryCode, label, onChange} = props;
    const {countries} = useContext(ConstantsContext);
    const value = countries.find((country) => country.code === countryCode?.toUpperCase())
    useEffect(() => {}, [countries])
    return <Autocomplete
        {...props}
        value={value??null}
        autoHighlight
        options={countries}
        getOptionLabel={(option) => option.name}
        sx={{ flexGrow: 1 }}
        renderInput={(params) => <TextField variant={"filled"} {...params} label={label} />}
        onChange={onChange}
    />
}