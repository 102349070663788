import React from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    TextField
} from "@mui/material";
import {t} from "i18next";
import Button from "@mui/material/Button";
import {useMutation} from "@apollo/client";
import mutateFilter from "../../query/mutateFilter";
import loadFilters from "../../query/loadFilters";
import {useSnackbar} from "notistack";

interface Props{
    isOpen: boolean
    setIsOpen: (open: boolean) => void
    filterState: URLSearchParams
}
export default function SaveFilterDialog(props: Props){
    const {isOpen, setIsOpen, filterState} = props;
    const { enqueueSnackbar } = useSnackbar();
    const [createFilter] = useMutation(mutateFilter, {
        refetchQueries:[
            loadFilters
        ]
    });
    const handleClose = () => {

        setIsOpen(false);
    }

    const getFilterParam = (name: string) => {
        return filterState.get(name)
    }
    const getFilterParamInt = (name: string) => {
        const result = filterState.get(name)
        if(result !== null){
            return parseInt(result);
        }
        return null;
    }
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    const name = formJson.name;
                    const notification = formJson.notification === "on";

                    console.log(formJson);
                    console.log(getFilterParam("priceCurrency"))
                    createFilter({
                        variables:{
                            filter: {
                                name,
                                notification,
                                isPublic: false,
                                priceFrom: getFilterParamInt("priceFrom"),
                                priceTo: getFilterParamInt("priceTo"),
                                priceCurrency: getFilterParam("priceCurrency") ?? "czk",

                                fromCountry: getFilterParam("fromCountry"),
                                fromAirport: getFilterParam("fromAirport"),
                                toCountry: getFilterParam("toCountry"),
                                toAirport: getFilterParam("toAirport"),

                                departureDateFrom: getFilterParamInt("departureDateFrom"),
                                departureDateTo: getFilterParamInt("departureDateTo"),
                                returnDateFrom: getFilterParamInt("returnDateFrom"),
                                returnDateTo: getFilterParamInt("returnDateTo"),

                            }
                        }
                    })
                        .then(() => {
                            enqueueSnackbar("Filtr byl úspěšně uložen", {
                                variant: "success",
                                preventDuplicate: true
                            });
                            handleClose()
                        })
                        .catch((reason) => console.log(reason))

                },
            }}
        >
            <DialogTitle>{t("filter.save.title")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("filter.save.description")}
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label={t("filter.save.input")}
                    fullWidth
                    variant="standard"
                />
                <FormControlLabel control={<Checkbox name="notification" defaultChecked />} label={t("filter.save.email-notification")} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("filter.save.cancel")}</Button>
                <Button type="submit">{t("filter.save.confirm")}</Button>
            </DialogActions>
        </Dialog>
    );
}