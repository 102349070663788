import styled from "styled-components";

export const SideBarTemplate = styled.div`
  /* https://web.dev/building-a-sidenav-component/ */
  display: grid;
  grid-template-rows: [stack] 1fr;
  grid-template-columns: min-content [stack] 2fr;
  min-height: 100vh;

  @media (max-width: 540px) {
    gap: 0;
    & > * {
      grid-area: stack;
    }
  }
`