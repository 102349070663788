import {Divider, Toolbar} from "@mui/material";
import {default as ActionsList} from "../components/ActionsList/List";
import React, {useState} from "react";
import {SideBar} from "../components/HomePage/Sidebar";
import AppHeader from "../components/UI/Molecules/AppHeader";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from "@mui/material/IconButton";
import {SideBarTemplate} from "../components/UI/Atoms/SideBarTemplate";
import FilterList from "../components/HomePage/FilterList";
import {useParams} from "react-router-dom";
import Filter from "../components/Filter/Filter";

export default function HomePage(){
    const {filterId, toCountryCode} = useParams();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const icon = (
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: 'none' }}}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
            <FilterAltIcon />
        </IconButton>
    );

    const drawer = (
        <div style={{paddingBottom: 56}}>
            <Toolbar />
            <Divider />
            <Filter closeSidebar={() => setIsSidebarOpen(false)}/>
            <FilterList activeFilter={filterId} toCountryCode={toCountryCode}/>
        </div>
    );

    return (
        <>
            <AppHeader icon={icon} title={"CoolLetenky"}/>
            <SideBarTemplate>
                <SideBar
                    isOpen={isSidebarOpen}
                    drawer={drawer}
                    onClose={() => setIsSidebarOpen(false)}
                    onOpen={() => setIsSidebarOpen(true)}
                />
                <div>
                    <ActionsList filterId={filterId} toCountryCode={toCountryCode}/>
                </div>
            </SideBarTemplate>
        </>
    )
}